
import { ref, defineComponent, provide, onMounted, computed } from 'vue'
import CustomTextArea from '@/components/UI/CustomTextArea.vue'
import Modal from '@/components/UI/Modal.vue'
import FuncErrorModal from '@/components/UI/Modal.vue'
import SubscriptionModal from '@/components/UI/Modal.vue'
import Button from './UI/Button.vue'
import router from '@/router'
import { useRoute } from 'vue-router'
import { useStore } from '@/store'
import { VoicesActionTypes as VoicesActionType } from '@/store/voices/actions'
import { VoicesMutationTypes } from '@/store/voices/mutations'
import { useI18n } from 'vue-i18n'
import { convertToSsml } from '@/utils/convertToSsml'
import { saveAs } from 'file-saver'
import { hashData } from '../utils/hashData'
import { modelContent } from '../types/index'
import { subscriptionModelContentType, allowEffectsType } from '../types/index'
import CustomTooltip from '@/components/UI/CustomToolTip.vue'
import VoiceTypeRadioButton from '@/components/UI/RadioButton.vue'
import { getVoices } from '@/utils/getVoices'
import { removeInvalidTags } from '@/utils/removeInvalidTags'
// import { ssmlToHtml } from "@/utils/ssmlToHtml";

export default defineComponent({
  components: {
    CustomTextArea,
    Button,
    Modal,
    CustomTooltip,
    FuncErrorModal,
    SubscriptionModal,
    VoiceTypeRadioButton
  },
  props: {
    textInputLength: {
      type: Number
    },
    disableApplyPlay: {
      type: Boolean
    },
    projectName: {
      type: String
    }
  },

  setup(props, { slots }) {
    const store = useStore()
    const { t } = useI18n()
    const { locale } = useI18n({ useScope: 'global' })
    const regex = /[\n\s\u200B]+/g
    const route = useRoute()
    const routeQuery = computed(() => route.query)
    const tabTitles = ref(slots.default().map((tab) => tab.props.title))
    const selectedTitle = ref(tabTitles.value[0])
    const maxLength = ref(1500)
    const txtlength = ref(0)
    const textInput = ref('')
    const disabled = ref(true)
    const disabledPlaySelect = ref(true)
    const disableDownload = ref(true)
    const disablesave = ref(true)
    const audioInput = ref()
    const audioPlaying = ref(false)
    const language = computed(() => store.state.voices.language)
    const selectedLanguage = computed(() => store.state.voices.selectedLanguage)
    const selectedVoice = computed(() => store.state.voices.selectedVoice)
    const speedValue = computed(() => store.state.voices.voiceSpeedValue)
    const pitchValue = computed(() => store.state.voices.voicePitchValue)
    const getFilteredVoices = computed(() => store.getters.filteredVoices)
    const src = computed(() => store.state.voices.setAudioURL)
    const playUrl = ref('')
    const stateloader = computed(() => store.state.voices.loader)
    const loader = ref()
    const isKeyUp = ref(false)
    const isdownloaded = ref(false)
    const actionButtonType = ref('')
    const userData = computed(() => JSON.parse(JSON.stringify(store.state.voices.userData)))
    const projectData = computed(() => JSON.parse(JSON.stringify(store.state.voices.projectData)))
    const projectPagesUserData = computed(() => JSON.parse(JSON.stringify(store.state.voices.projectPagesUserData)))
    const tempUserData = computed(() => JSON.parse(JSON.stringify(store.state.voices.tempUserData)))
    const funcError = computed(() => store.state.voices.funcError)
    const voiceType = computed(() => store.state.voices.voiceType)
    const openModal = ref(false)
    const isUserLoggedIn = ref(false)
    const mute = ref(true)
    const maxPlaysPerDay = 3
    const playedTodayCount = ref(0)
    const lastPlayedDate = ref(null)
    const previousDate = ref(null)
    let playedUrlAndData = [{ url: src.value, text: '', language: '', name: '', ssmlGender: '' }]
    const modalContent = ref<modelContent>({
      modelTitle: 'title',
      modelDescription: 'description',
      ref: 'vog'
    } as modelContent)
    const subscriptionModelContent = ref<subscriptionModelContentType>({
      subscriptionModelTitle: 'title',
      subscriptionModelDescription: 'description'
    } as subscriptionModelContentType)
    const openfuncErrorModal = ref(false)
    const openSubscriptionModal = ref(false)
    const errorType = ref('audio')
    const unlimitedDownload = ref(false)
    const remainingDownloads = ref(0)
    const userActivePlan = ref()
    const allowedEffects = ref<allowEffectsType[]>([
      { effectName: t('_tabNames.speed'), effectEnabled: false },
      { effectName: t('_tabNames.pitch'), effectEnabled: false },
      { effectName: t('_tabNames.silence'), effectEnabled: false },
      { effectName: t('_tabNames.emphasis'), effectEnabled: false }
    ])
    const neural2Enabled = ref(false)
    const showTooltip = ref(false)

    const langName = (language) => {
      const langName = language
        .filter((voice) => voice.value === selectedLanguage.value)
        .map((voice) => {
          return voice.label
        })
      return langName[0]
    }

    const voiceName = (selectedVoice) => {
      const voiceName = selectedVoice.map((voice) => {
        return { voiceLabel: voice.label, voiceIndex: voice.value + 1 }
      })
      return voiceName[0]
    }

    const closeModal = (modaltype) => {
      switch (modaltype) {
        case 'Modal':
          openModal.value = false
          break
        case 'FuncError':
          openfuncErrorModal.value = false
          store.commit(VoicesMutationTypes.SET_FUNC_ERROR, '')
          break
        case 'SubscriptionModal':
          openSubscriptionModal.value = false
      }
    }

    const checkUser = (userData, projectData, projectPagesUserData) => {
      if (isKeyUp.value == false) {
        txtlength.value = props.textInputLength || 0
      }
      disablesave.value = txtlength.value > 0 && routeQuery.value.token && props.projectName ? false : true
      if (Object.keys(projectPagesUserData).length) {
        isUserLoggedIn.value = true
        maxLength.value = projectPagesUserData.subscription.permissions.max_script_length
        userActivePlan.value = projectPagesUserData.subscription.plan
        allowedEffects.value[0].effectEnabled = projectPagesUserData.subscription.permissions.speed
        allowedEffects.value[1].effectEnabled = projectPagesUserData.subscription.permissions.pitch
        allowedEffects.value[2].effectEnabled = projectPagesUserData.subscription.permissions.silence
        allowedEffects.value[3].effectEnabled = projectPagesUserData.subscription.permissions.emphasize
        neural2Enabled.value = projectPagesUserData.subscription.permissions.neural_2
      }
      if (Object.keys(userData).length && Object.keys(projectData).length) {
        maxLength.value = projectData.user.subscription.permissions.max_script_length
        disabled.value = txtlength.value > 0 ? false : true
        isUserLoggedIn.value = true
        unlimitedDownload.value = projectData.user.subscription.permissions.downloads.unlimited
        remainingDownloads.value = projectData.user.subscription.permissions.downloads.remaining
        userActivePlan.value = projectData.user.subscription.plan
        allowedEffects.value[0].effectEnabled = projectData.user.subscription.permissions.speed
        allowedEffects.value[1].effectEnabled = projectData.user.subscription.permissions.pitch
        allowedEffects.value[2].effectEnabled = projectData.user.subscription.permissions.silence
        allowedEffects.value[3].effectEnabled = projectData.user.subscription.permissions.emphasize
        neural2Enabled.value = projectData.user.subscription.permissions.neural_2

        if (unlimitedDownload.value) {
          disableDownload.value = txtlength.value > 0 ? false : true
        } else if (remainingDownloads.value > 0) {
          disableDownload.value = txtlength.value > 0 ? false : true
        }
      } else {
        disableDownload.value = txtlength.value > 0 ? false : true
      }
      if (funcError.value.length > 1) {
        openfuncErrorModal.value = true
      }
    }

    const onKeyUp = (e) => {
      isKeyUp.value = true
      txtlength.value = e.target.innerText.replace(regex, '').length
      textInput.value = e.target.innerText.substring(0, maxLength)
      disabled.value = txtlength.value > 0 ? false : true
      if (e.target.className == 'tarea' && (e.key == 'Backspace' || e.key == 'Delete' || e.type == 'paste')) {
        e.target.normalize()
        const lstChar = e.target.innerText.substring(e.target.innerText.length - 1)
        if (lstChar.match(/^\s*$/) == null) {
          e.target.insertAdjacentHTML('beforeend', '&nbsp;')
        }

        if (e.target.innerHTML.substring(0, 5) == '<span') {
          e.target.insertAdjacentHTML('afterbegin', '&nbsp;')
        }
      }
    }

    const onKeyPress = (e) => {
      txtlength.value = e.target.innerText.replace(regex, '').length
      if (txtlength.value >= maxLength.value) {
        e.preventDefault()
        if (!projectData.value.user) {
          openModal.value = true
          modalContent.value = {
            modelTitle: 'maxScriptTitle',
            modelDescription: 'maxScriptDescription',
            ref: 'vog-max-script'
          }
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_open_signup_popup', // fixed value
                eventCategory: 'voice_over_generator', // fixed value
                eventLabel: 'vog-max-script' // value depends on reason for popup to trigger (see list of values)
              })
            : null
        } else if (userActivePlan.value == 'Freemium' || userActivePlan.value == 'Bronze') {
          subscriptionModelContent.value = {
            subscriptionModelTitle: `scriptLimit${maxLength.value}Title`,
            subscriptionModelDescription: 'scriptLimitDescription'
          }
          openSubscriptionModal.value = true
        }
        if (!userData.value.ID && !routeQuery.value.token) {
          e.target.blur()
        }
      }
    }

    const disableTabs = (title, index) => {
      let disableTab = true
      if (title == t('_tabNames.voice')) {
        disableTab = false
      } else if (allowedEffects.value[index - 1].effectEnabled == true) {
        disableTab = false
      }
      return disableTab
    }

    const tabsNavigation = (title) => {
      if (isUserLoggedIn.value) {
        allowedEffects.value.map((value) => {
          if (title == t('_tabNames.voice') || (value.effectName == title && value.effectEnabled == true)) {
            selectedTitle.value = title
          } else if (title == t('_tabNames.voice') || (value.effectName == title && value.effectEnabled == false)) {
            subscriptionModelContent.value = {
              subscriptionModelTitle: `effectsDisabledTitle`,
              subscriptionModelDescription: 'effectsDisabledDescription'
            }
            openSubscriptionModal.value = true
          }
        })
        // selectedTitle.value = title
      } else {
        if (title != t('_tabNames.voice')) {
          openModal.value = true
          modalContent.value = { modelTitle: 'title', modelDescription: 'description', ref: 'vog' }
          if (title != t('_tabNames.speed') && title != t('_tabNames.pitch')) {
            'dataLayer' in window
              ? window.dataLayer.push({
                  event: 'vog_open_signup_popup', // fixed value
                  eventCategory: 'voice_over_generator', // fixed value
                  eventLabel: `vog-${title.toLowerCase()}` // value depends on reason for popup to trigger (see list of values)
                })
              : null
          }
        } else {
          selectedTitle.value = title
        }
      }
    }

    const getAudio = async (selectedLanguage, selectedVoice, ssml) => {
      const el = document.getElementsByClassName('tarea')[0]
      const textLength = el.textContent.replace(regex, '').length
      const sv = selectedVoice[0].voiceCode
      const ssmlgender = selectedVoice[0].label
      if (textLength > maxLength.value) {
        if (!userData.value.ID) {
          openModal.value = true
          modalContent.value = {
            modelTitle: 'maxScriptTitle',
            modelDescription: 'maxScriptDescription',
            ref: 'vog-max-script'
          }
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_open_signup_popup', // fixed value
                eventCategory: 'voice_over_generator', // fixed value
                eventLabel: 'vog-max-script' // value depends on reason for popup to trigger (see list of values)
              })
            : null
        }
      } else {
        const payload = {
          input: { ssml: ssml },
          voice: { languageCode: selectedLanguage, name: sv, ssmlGender: ssmlgender },
          audioConfig: {
            // speakingRate: speedValue.value.toString(),
            // pitch: pitchValue.value,
          }
        }
        const hashssml = await hashData(ssml)

        const playData = playedUrlAndData.filter(
          (i) => i.text == hashssml && i.language == selectedLanguage && i.name == sv && i.ssmlGender == ssmlgender
        )

        if (playData.length > 0) {
          const url = playData[0].url
          playUrl.value = ''
          store.commit(VoicesMutationTypes.SET_AUDIO_URL, url)
        } else {
          await store.dispatch(VoicesActionType.GET_AUDIO_URL, payload)
          if (src.value && funcError.value.length < 1) {
            playedUrlAndData.push({
              url: src.value,
              text: hashssml,
              language: selectedLanguage,
              name: sv,
              ssmlGender: ssmlgender
            })
            localStorage.setItem('playedUrlAndData', JSON.stringify(playedUrlAndData))
          }
        }
      }
      if (actionButtonType.value != 'save') {
        'dataLayer' in window
          ? window.dataLayer.push({
              event: `vog_${actionButtonType.value}_item`,
              eventCategory: 'voice_over_generator',
              eventAction: `vog_${actionButtonType.value}`,
              eventLabel: `vog-editor-text_${ssml}`
            })
          : null
      }
      if (actionButtonType.value == 'play' || actionButtonType.value == 'play_selection') {
        fetch(src.value)
          .then((response) => {
            if (!response.ok) {
              throw new Error('Error while playing the audio')
            }
            response.arrayBuffer().then((ArrayBuffer) => {
              const blob = new Blob([ArrayBuffer], { type: 'audio/wav' })
              const reader = new FileReader()
              reader.readAsDataURL(blob)
              reader.onloadend = function () {
                const base64data = reader.result
                playUrl.value = base64data.toString()
              }
            })
          })
          .catch((error) => {
            console.error(error)
            store.commit(VoicesMutationTypes.SET_AUDIO_URL, '')
          })

        mute.value = true
        audioInput.value.oncanplay = () => {
          mute.value = false
          audioInput.value.play()
          audioPlaying.value = !audioPlaying.value
        }
      }
    }

    const play = (selectedLanguage, selectedVoice) => {
      actionButtonType.value = 'play'
      const el = document.getElementsByClassName('tarea')[0]
      removeInvalidTags(el)
      const ssml = convertToSsml(el.outerHTML)
      const today = new Date().toISOString().slice(0, 10).toString()
      errorType.value = 'audio'
      if (lastPlayedDate.value !== today) {
        playedTodayCount.value = 0
        // playedUrlAndData = []
      }
      if (previousDate.value !== today) {
        playedUrlAndData = []
        previousDate.value = today
      }
      localStorage.setItem('previousDate', previousDate.value)
      if (userData.value.ID || routeQuery.value.token) {
        getAudio(selectedLanguage, selectedVoice, ssml)
      } else {
        if (playedTodayCount.value < maxPlaysPerDay) {
          playedTodayCount.value++
          lastPlayedDate.value = today
          getAudio(selectedLanguage, selectedVoice, ssml)
        } else {
          openModal.value = true
          modalContent.value = {
            modelTitle: 'maxPlaysTitle',
            modelDescription: 'maxPlaysDescription',
            ref: 'vog-max-plays'
          }
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_open_signup_popup', // fixed value
                eventCategory: 'voice_over_generator', // fixed value
                eventLabel: 'vog-max-plays' // value depends on reason for popup to trigger (see list of values)
              })
            : null
        }
        localStorage.setItem('clicksToday', `${playedTodayCount.value}`)
        localStorage.setItem('lastClickDate', lastPlayedDate.value)
      }
    }

    const playSelection = () => {
      actionButtonType.value = 'play_selection'
      const selection = document.getSelection()
      errorType.value = 'audio'
      let selrange = null
      if (selection.rangeCount > 0) {
        selrange = selection.getRangeAt(0)
        if (
          selection.anchorNode.parentElement.className == 'container' ||
          selection.anchorNode.parentElement.className == 'tarea' ||
          (selection.anchorNode.parentElement.attributes.getNamedItem('data-val') && selrange.collapsed == false)
        ) {
          let tmpOuterHTML = ''
          if (
            selrange.commonAncestorContainer.nodeName == '#text' &&
            selrange.commonAncestorContainer.parentElement.className != 'tarea'
          ) {
            // check if parentElement has attribute "data-val"
            if (selrange.commonAncestorContainer.parentElement.attributes.getNamedItem('data-val')) {
              const span = document.createElement('span')
              span.className = selrange.commonAncestorContainer.parentElement.className
              const dataVal = selrange.commonAncestorContainer.parentElement.attributes.getNamedItem('data-val').value
              span.setAttribute('data-val', dataVal)
              span.appendChild(selrange.cloneContents())
              tmpOuterHTML = '<span class="tarea">' + span.outerHTML + '</span>'
            }
          }

          if (tmpOuterHTML == '') {
            const span = document.createElement('span')
            span.classList.add('tarea')
            span.appendChild(selrange.cloneContents())
            tmpOuterHTML = span.outerHTML
          }

          const ssml = convertToSsml(tmpOuterHTML)
          if (userData.value.ID || routeQuery.value.token) {
            getAudio(selectedLanguage.value, selectedVoice.value, ssml)
          } else {
            const today = new Date().toISOString().slice(0, 10)
            lastPlayedDate.value !== today ? (playedTodayCount.value = 0) : null
            if (playedTodayCount.value < maxPlaysPerDay) {
              playedTodayCount.value++
              lastPlayedDate.value = today
              getAudio(selectedLanguage.value, selectedVoice.value, ssml)
            } else {
              openModal.value = true
            }
            localStorage.setItem('clicksToday', `${playedTodayCount.value}`)
            localStorage.setItem('lastClickDate', lastPlayedDate.value)
          }
          // getAudio(selectedLanguage.value, selectedVoice.value, ssml);
        }
      }
    }

    const stopAudio = () => {
      'dataLayer' in window
        ? window.dataLayer.push({
            event: 'vog_stop_item',
            eventCategory: 'voice_over_generator',
            eventAction: 'vog_stop',
            eventLabel: 'vog_stop'
          })
        : null
      audioInput.value.pause()
      audioPlaying.value = false
      playUrl.value = ''
      store.commit(VoicesMutationTypes.SET_AUDIO_URL, '')
    }

    const downloadAudioUrl = async (selectedLanguage, selectedVoice, projectData) => {
      actionButtonType.value = 'download'
      if (userData.value.ID && !disableDownload.value) {
        actionButtonType.value = 'download'
        const el = document.getElementsByClassName('tarea')[0]
        const ssml = convertToSsml(el.outerHTML)
        actionButtonType.value = 'download'
        errorType.value = 'download'
        await getAudio(selectedLanguage, selectedVoice, ssml)
        audioPlaying.value = false
        try {
          if (src.value.length > 0 && funcError.value.length < 1) {
            await store.dispatch(VoicesActionType.UPDATE_DOWNLOAD_COUNT, projectData.downloads_count)
            window.parent.postMessage({ type: 'download', name: projectData.name }, '*')
            isdownloaded.value = true
            await fetch(src.value)
              .then((res) => res.blob())
              .then((arrayBuffer) => {
                const wavBlob = new Blob([arrayBuffer], { type: 'audio/wav' })
                const url = URL.createObjectURL(wavBlob)
                saveAs(url, 'voicebooking-speech.wav')
              })
            isdownloaded.value = false
          }
        } catch (error) {
          console.error(error)
          store.commit(VoicesMutationTypes.SET_LOADER, false)
          store.commit(VoicesMutationTypes.SET_AUDIO_URL, '')
          isdownloaded.value = false
          openfuncErrorModal.value = true
        }
      } else if (!routeQuery.value.token) {
        //openModal
        openModal.value = true
        modalContent.value = { modelTitle: 'title', modelDescription: 'description', ref: 'vog' }
        'dataLayer' in window
          ? window.dataLayer.push({
              event: 'vog_open_signup_popup', // fixed value
              eventCategory: 'voice_over_generator', // fixed value
              eventLabel: 'vog-download' // value depends on reason for popup to trigger (see list of values)
            })
          : null
      }

      if (!unlimitedDownload.value && remainingDownloads.value < 1 && projectData.user) {
        subscriptionModelContent.value = {
          subscriptionModelTitle: 'downloadLimitTitle',
          subscriptionModelDescription: 'downloadLimitDescription'
        }
        openSubscriptionModal.value = true
      }
    }

    const saveData = async (speedValue, pitchValue, selectedLanguage, selectedVoice) => {
      actionButtonType.value = 'save'
      if (!disablesave.value) {
        const el = document.getElementsByClassName('tarea')[0]
        removeInvalidTags(el)
        const ssml = convertToSsml(el.outerHTML)
        const projectId = routeQuery.value.projectid ? routeQuery.value.projectid : 'test'
        actionButtonType.value = 'save'
        const payload = {
          languageCode: selectedLanguage,
          voice: selectedVoice[0].voiceCode,
          pitchNum: pitchValue,
          speedPercentage: speedValue.toString(),
          insertText: ssml,
          version: '2.0',
          slateData: el.innerHTML.replaceAll('cream-teal', ''),
          userId: userData.value.userId,
          projectId: projectId
        }
        await store.dispatch(VoicesActionType.SAVE_USER_DATA, payload)
        if (routeQuery.value.projectId) {
          const payload = JSON.stringify({
            // eslint-disable-next-line @typescript-eslint/camelcase
            download_id: `${userData.value.ID}`,
            name: `${props.projectName}`,
            text: `${el.textContent.substring(0, maxLength.value)}`
          })
          await store.dispatch(VoicesActionType.UPDATE_PROJECT, payload)
          window.parent.postMessage({ type: 'save', slug: projectData.value.slug }, '*')
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_save_item',
                eventCategory: 'voice_over_generator',
                eventLabel: `vog_project_slug_${routeQuery.value.projectId}`
              })
            : null
        } else if (routeQuery.value.token) {
          const payload = JSON.stringify({
            // eslint-disable-next-line @typescript-eslint/camelcase
            download_id: `${userData.value.ID}`,
            name: `${props.projectName}`,
            text: `${el.textContent.substring(0, maxLength.value)}`
          })
          await store.dispatch(VoicesActionType.CREATE_PROJECT, payload)
          window.parent.postMessage({ type: 'save', slug: projectData.value.slug }, '*')
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_create_item',
                eventCategory: 'voice_over_generator',
                eventLabel: `vog_project_slug_${projectData.value.slug}`
              })
            : null
          await router.push({
            name: 'VoiceOverGenerator',
            query: {
              projectId: projectData.value.slug,
              lang: routeQuery.value.lang,
              token: routeQuery.value.token,
              apiUrl: routeQuery.value.apiUrl
            }
          })
        }
      }
    }

    const clearEffets = () => {
      function clearTags(tabName) {
        const tagsElement = document.getElementsByClassName(tabName)

        for (let i = tagsElement.length; i > 0; i--) {
          // const ancestorElement = tagsElement[i - 1].parentElement.parentElement;
          tagsElement[i - 1].insertAdjacentHTML('beforebegin', tagsElement[i - 1].innerHTML.replace(/\s/i, ''))
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_clear_effects_item',
                eventCategory: 'voice_over_generator',
                eventLabel: `vog_clear_effects_${tabName}`
              })
            : null
          tagsElement[i - 1].remove()
          const el = document.getElementsByClassName('tarea')[0]
          el.normalize()
          // ancestorElement.innerHTML += "";
          // tagsElement[i - 1].parentElement.innerHTML += "";
        }
      }
      switch (selectedTitle.value) {
        case t('_tabNames.voice'):
          store.commit(VoicesMutationTypes.SET_SELECTED_LANGUAGE, 'en-GB')
          store.commit(VoicesMutationTypes.SET_TEMP_SELECTED_LANGUAGE, 'en-GB')
          store.commit(
            VoicesMutationTypes.SET_SELECTED_VOICE,
            getFilteredVoices.value.filter((voice) => voice.value == 0)
          )
          'dataLayer' in window
            ? window.dataLayer.push({
                event: 'vog_clear_effects_item',
                eventCategory: 'voice_over_generator',
                eventLabel: 'vog_clear_effects_voice_tab'
              })
            : null

          break
        case t('_tabNames.speed'):
          store.commit(VoicesMutationTypes.SET_VOICE_SPEED_VALUE, 1)
          clearTags('speed')
          break
        case t('_tabNames.pitch'):
          store.commit(VoicesMutationTypes.SET_VOICE_PITCH_VALUE, 0)
          clearTags('pitch')
          break
        case t('_tabNames.silence'):
          store.commit(VoicesMutationTypes.SET_VOICE_SILENCE_VALUE, '')
          clearTags('silence')
          break
        case t('_tabNames.emphasis'):
          store.commit(VoicesMutationTypes.SET_VOICE_EMPHASIS_VALUE, '')
          clearTags('emphasis')
          break
      }
    }

    const openProjectPages = async (action, speedValue, pitchValue, selectedLanguage, selectedVoice, ref) => {
      const el = document.getElementsByClassName('tarea')[0]
      const ssml = convertToSsml(el.outerHTML)
      const payload = {
        insertText: ssml,
        languageCode: selectedLanguage,
        pitchNum: pitchValue,
        speedPercentage: speedValue.toString(),
        version: '2.0',
        voice: selectedVoice[0].voiceCode
      }
      await store.dispatch(VoicesActionType.GET_TEMP_USER_DATA, payload)
      const gid = tempUserData.value.ID
      const lang = locale.value
      const name = txtlength.value > 0 ? el.textContent.substring(0, 20) : 'New Project'
      const currency = routeQuery.value.cur
      let projectPagesRedirect = `${process.env.VUE_APP_PP_URL}/wp/generator?gid=${gid}&lang=${lang}&action=${action}&name=${name}`
      if (action == 'register') {
        projectPagesRedirect += `&ref=${ref}&currency=${currency}`
      }
      window.open(projectPagesRedirect, '_blank')
    }

    const gotoSubscriptionPage = () => {
      window.open(`${process.env.VUE_APP_PP_URL}/client/subscription`, '_blank')
    }

    const voiceTypeChange = (voiceType) => {
      store.commit(VoicesMutationTypes.SET_VOICE_TYPE, voiceType)
      store.commit(VoicesMutationTypes.SET_SELECTED_LANGUAGE, 'en-GB')
      store.commit(
        VoicesMutationTypes.SET_SELECTED_VOICE,
        getVoices(selectedLanguage.value, getFilteredVoices.value, t).filter((voice) => voice.value == 0)
      )
    }

    const showVoiceTypeModal = () => {
      if (!neural2Enabled.value) {
        subscriptionModelContent.value = {
          subscriptionModelTitle: `effectsDisabledTitle`,
          subscriptionModelDescription: 'effectsDisabledDescription'
        }
        openSubscriptionModal.value = true
      }
    }

    const onCutOrCopy = () => {
      const selection = document.getSelection()
      if (selection.rangeCount > 0) {
        const range = selection.getRangeAt(0)
        const fragment = range.cloneContents()
        const serializer = new XMLSerializer()
        const selectedHtml = serializer.serializeToString(fragment)
        navigator.clipboard.writeText(selectedHtml.replaceAll('xmlns="http://www.w3.org/1999/xhtml" ', ''))
      }
    }

    onMounted(async () => {
      playedUrlAndData = JSON.parse(localStorage.getItem('playedUrlAndData')) || []
      playedTodayCount.value = parseInt(localStorage.getItem('clicksToday')) || 0
      lastPlayedDate.value = localStorage.getItem('lastClickDate')
      previousDate.value = localStorage.getItem('previousDate')
      await audioInput.value.addEventListener('ended', () => {
        store.commit(VoicesMutationTypes.SET_AUDIO_URL, '')
        stopAudio()
      })
    })

    provide('selectedTitle', selectedTitle)
    return {
      selectedTitle,
      tabTitles,
      maxLength,
      onKeyUp,
      onKeyPress,
      tabsNavigation,
      txtlength,
      textInput,
      disabled,
      disabledPlaySelect,
      disableDownload,
      disablesave,
      language,
      langName,
      selectedLanguage,
      selectedVoice,
      voiceName,
      getAudio,
      speedValue,
      pitchValue,
      src,
      playUrl,
      clearEffets,
      audioInput,
      audioPlaying,
      actionButtonType,
      stopAudio,
      playSelection,
      play,
      checkUser,
      userData,
      projectData,
      projectPagesUserData,
      openModal,
      downloadAudioUrl,
      saveData,
      isUserLoggedIn,
      openProjectPages,
      loader,
      stateloader,
      isdownloaded,
      onload,
      onmousedown,
      routeQuery,
      mute,
      modalContent,
      subscriptionModelContent,
      funcError,
      closeModal,
      openfuncErrorModal,
      errorType,
      openSubscriptionModal,
      gotoSubscriptionPage,
      allowedEffects,
      disableTabs,
      voiceTypeChange,
      neural2Enabled,
      voiceType,
      showTooltip,
      showVoiceTypeModal,
      onCutOrCopy
    }
  }
})
