<template>
  <select :class="selectType" @change="$emit('onChange', $event)">
    <span></span>
    <option
      v-for="(item, index) of items"
      :key="index"
      :value="item.value"
      :selected="item.value === item.selected ? true : false"
    >
      {{ selectType == 'voice' ? index + 1 + '. ' + item.label : $t('_dropDownContent.' + item.label) }}
    </option>
  </select>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    placeholder: {
      type: String,
      default: 'Select'
    },
    items: {
      type: Array as () => { value: string; label: string; selected: string }[],
      required: true
    },
    selectType: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    }
  }
})
</script>

<style lang="sass" scoped>
select
  width: 50%
  padding: 10px 12px
  font-family: 'Faro-Variable'
  font-style: normal
  font-weight: 376
  font-size: 16px
  line-height: 150%
  color: $colorDarkBlue
  -webkit-appearance: none
  border: 1px solid #EDEDE8
  border-radius: 4px
  background: url('~@/assets/Vector.svg')
  background-repeat: no-repeat
  background-position-x: calc( 100% - 1rem )
  background-position-y: 50%
  &:focus-visible
    outline: none
    border: 1px solid #EDEDE8
    background-image: url('~@/assets/arrowdown.svg')
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
    &:focus-visible
      width: 100%
.silenceSelect
  width: 91.7%
  @media screen and (max-width: $xsBreakpoint)
    width: 100%
    &::focus-visible
      width: 100%
</style>
