<template>
  <div :class="toolTipType + 'tooltip-container'" v-if="disableTooltip">
    {{ toolTipText }}
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
export default defineComponent({
  props: {
    toolTipType: {
      type: String,
      default: 'customtoottip'
    },
    toolTipText: {
      type: String,
      default: 'This is a tooltip'
    },
    disableTooltip: {
      type: Boolean
    }
  }
})
</script>
<style lang="sass">
.downloadbtntooltip-container
  @include customToolTip(63px)
  @media screen and (max-width: $xsBreakpoint)
    width: 80%
    left: 155px


.applybtntooltip-container
  @include customToolTip(0)
  @media screen and (max-width: $xsBreakpoint)
    width: 100%

// .tooltip
//   width: 180px
//   color: #08082B
//   font-family: Faro-Variable
//   padding: 10px 8px
//   border-radius: 4px
//   opacity: 0
//   background: #FFF
//   z-index: 1
//   box-shadow: 1px 1px 8px rgba(#000, 0.08)
//   font-size: 12px
// .hide
//   display: none
</style>
