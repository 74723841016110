const getVoices = (selectedLanguage, getFilteredVoices, t) => {
    let m = 0
    let f = 0
    const voices = getFilteredVoices.map((voice, i) => {
        return {
            value: i,
            label:
                voice.label == 'MALE'
                    ? t(selectedLanguage + '.' + voice.label + m++)
                    : t(selectedLanguage + '.' + voice.label + f++),
            gender: voice.label,
            voiceCode: voice.voiceCode,
            selected: voice.selected
        }
    })
    return voices
}

export { getVoices }